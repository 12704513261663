import React from "react";
import List from "../../shared/List";
import helpers from "../../shared/helpers";
import JobList from "./JobList";


export default function Events(){

  return (<>
    <h2>Job Listings</h2>
    <JobList limit={30} type="active" title="Active Job Listings"/>



  </>);
}
