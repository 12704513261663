export function eventLink(event){
    var prefix = "https://mauinow.com/events/event/";
    if(event.calendarID == "big-island"){
        prefix = "https://bigislandnow.com/events/event/";
    }
    if(event.calendarID == "kauai"){
        prefix = "https://kauainownews.com/community-calendar/event/";
    }
    return `${prefix}${event.eventHash}`;
}

export function eventEditLink(event){
    var prefix = "https://mauinow.com/events/edit/";
    if(event.calendarID == "big-island"){
        prefix = "https://bigislandnow.com/events/edit/";
    }
    if(event.calendarID == "kauai"){
        prefix = "https://kauainownews.com/community-calendar/edit/";
    }
    return `${prefix}${event.eventHash}`;
}

export function jobLink(job){
    var prefix = "https://mauinow.com/maui-jobs/listing/";
    if(job.island == "big-island"){
        prefix = "https://bigislandnow.com/hawaii-jobs/listing/";
    }
    if(job.island == "kauai"){
        prefix = "https://kauainownews.com/kauai-jobs/listing/";
    }
    return `${prefix}${job.hashKey}`;
}

export function pauseJobLink(job){
    var prefix = "https://mauinow.com/maui-jobs/pause/";
    if(job.island == "big-island"){
        prefix = "https://bigislandnow.com/hawaii-jobs/pause/";
    }
    if(job.island == "kauai"){
        prefix = "https://kauainownews.com/kauai-jobs/pause/";
    }
    return `${prefix}${job.hashKey}`;
}

export function editJobLink(job){
    var prefix = "https://mauinow.com/maui-jobs/edit/";
    if(job.island == "big-island"){
        prefix = "https://bigislandnow.com/hawaii-jobs/edit/";
    }
    if(job.island == "kauai"){
        prefix = "https://kauainownews.com/kauai-jobs/edit/";
    }
    return `${prefix}${job.hashKey}`;
}