const config = {
  obituaries:{
    API_DOMAIN:"https://obituaries.hubhawaii.com/"
  //  API_DOMAIN:"http://obituaries.localhost:8003/"
  },
  comments:{
  //  API_DOMAIN:"http://localhost:8000/"
    API_DOMAIN:"https://hubhawaii.com/"
  },
  events:{
    //API_DOMAIN:"http://localhost:8003/eventListings/admin/"
    API_DOMAIN:"https://events.hubhawaii.com/eventListings/admin/"
  },
  jobs:{
    //API_DOMAIN:"http://localhost:8003/jobListings/admin/listings/"
    API_DOMAIN:"https://api.hubhawaii.com/jobListings/admin/listings/"
  },
  payments:{
    //  API_DOMAIN:"http://localhost:8003/payments/admin/"
      API_DOMAIN:"https://api.hubhawaii.com/payments/admin/"
  },
  user:{
    //API_DOMAIN:"http://localhost:8000/"
    API_DOMAIN:"https://hubhawaii.com/"
  },
  businessdirectory:{
    //API_DOMAIN:"http://localhost:8000/"
    API_DOMAIN:"https://hubhawaii.com/"
  },

};

export default config;
