import React from "react";
import List from "../../shared/List";
import helpers from "../../shared/helpers";
import config from "../../config";
import Popup from "../../shared/Popup";
import Form from "../../shared/Form";
import {eventLink,eventEditLink,jobLink,editJobLink,pauseJobLink} from "./helpers";
import {ajaxPOST,ajaxGET} from "../../ajax";


export default function JobList({type,slug,title,limit,titleLink,island,query,active,key}){

  const [showPopup,setShowPopup] = React.useState(false);
  const [actionSuccess,setActionSuccess] = React.useState(false);
  const [actionLoading,setActionLoading] = React.useState(false);
  const [focusItem,setFocusItem] = React.useState(false);
  const [focusItemDetails,setFocusItemDetails] = React.useState(false);
  const [optionItem,setOptionItem] = React.useState(false);
  const [updateData,setUpdateData] = React.useState(false);
  const [showForm,setShowForm] = React.useState(false);
  const [selected,setSelected] = React.useState([]);
  const checkboxRef = React.useRef([]);

  const hidePopup = () => {
    setShowPopup(false);
    setActionLoading(false);
    setActionSuccess(false);
    setOptionItem(false);
  };

  const showOptions = (item) => {
    setOptionItem(item);
    setShowPopup(true);

  };


  React.useEffect(()=>{
    console.log(selected);
  },[selected]);


  const rowAction = async (action,data,loading) => {
    setActionLoading(loading);
    var formData = new FormData;

    var result = await ajaxPOST(config.jobs.API_DOMAIN+action,data);

    setActionSuccess(result.message);
    if(action == "delete"){
      setUpdateData({
        key:'hashKey',
        keyValue:data.hashKey,
        newData:"delete"
      });
    }
  };

  /*
Free Comic Book Day @ Kihei Public Library
Sat, May 4, 2024
Approve Event	maui	

	GUITAR NIGHT Slack Key & Fingerstyle Guitar
Sat, May 18, 2024
Approve Event	big-island	

	Henry Kaponos Artist2Artist Concert & Kalae Parish, Kalenaku, Pat
Fri, May 3, 2024
big-island
  */

  React.useEffect(async ()=>{
    if(focusItem === false) return;
    var result = await ajaxGET(config.jobs.API_DOMAIN+"get",{'jobHash':focusItem.hashKey});
    if(result){
      setFocusItemDetails(result);
    }
  },[focusItem]);

  React.useEffect(async ()=>{
    if(optionItem === false) return;

  },[showForm]);

  const selectAll = () => {
    checkboxRef.current.forEach((c)=>{
      if(!c.checked){
        c.click();
      }
    })
  }

  const selectNone = () => {
    setSelected([]);
  }

  const multipleApprove = () => {
    selected.forEach(s=>{
      rowAction("approve",{eventHash:s.eventHash})
    });
  }
  const multipleDelete = () => {
    selected.forEach(s=>{
      rowAction("delete",{eventHash:s.eventHash})
    });
  }

  var urlGET = `${config.jobs.API_DOMAIN}${type}${island ? `?island=${island}` : ``}`;
  if(query && query !== ""){
    urlGET = `${config.jobs.API_DOMAIN}search?query=${query}${island ? `&island=${island}` : ``}${active ? `&active=true` : ``}`
  }

  return (<>


    <Popup
     show={showPopup}
     hide={hidePopup}>
     {actionLoading ?
       (<>
         {actionSuccess ? (<>
           <center>{actionSuccess}</center>
           <ul className="optionsList">
            <li onClick={hidePopup}>OK</li>
           </ul>
         </>) : (<center>{actionLoading}</center>)}
       </>) : (<>
         <b style={{display:"block",textAlign:"center",paddingBottom:10}}>{optionItem.title}</b>         
        <ul className="optionsList">
          <li onClick={()=>rowAction("delete",{'eventHash':optionItem.eventHash,'slug':slug},"Deleting Event")} className="">Delete Job</li>
          <li onClick={()=>window.open(editJobLink(optionItem))}>Edit Job</li>

          <li onClick={hidePopup} className="cancel">Cancel</li>
         </ul>
       </>)}
    </Popup>

    <Popup
      show={focusItem}
      hide={()=>{ setFocusItem(false); setFocusItemDetails(false); }}
    >
      <div className="details">
        {focusItem.logoImage && (<img src={focusItem.logoImage}/>)}
        <h2 style={{marginBottom:0,paddingBottom:0}}>{focusItem.jobTitle}</h2>
        {focusItem.businessName}
        <center>
          <button className="button2 red" onClick={()=>{ showOptions(focusItem); setFocusItem(false); rowAction("delete",{'hashKey':focusItem.hashKey},"Removing Job Listing"); }}>Remove</button>
          <button className="button2" onClick={()=>{ window.open(editJobLink(focusItem)) }}>Edit</button>
          <button className="button2" onClick={()=>{ window.open(pauseJobLink(focusItem)) }}>Pause</button>
        </center>

        <div className="section">
          <b>Posted</b>
          <span>{focusItem.timeAddedString} (Expires in {focusItem.validThruString})</span>
        </div>
        <div className="section">
          <b>Submitted By</b>
          <span>{focusItem.isFeed ? "From CareerJet.com Feed" : focusItem.name}</span>
        </div>
        <div className="section">
          <b>Resume Email</b>
          <span>{focusItem.resumeEmail}</span>
        </div>
        <div className="section">
          <b>Description</b>
          <span dangerouslySetInnerHTML={{__html:focusItemDetails?.description}}></span>
        </div>

        <div className="section">
          <b>Location</b>
          {focusItem.address}
        </div>

        <div className="section">
          <b>Category</b>
          {focusItem.category && focusItem.category.replace(/,/g,", ")}
        </div>


      </div>

    </Popup>
      <button onClick={async ()=>{
        var result = await ajaxPOST(config.jobs.API_DOMAIN+"cacheupdate",{});
        alert(result.message);
      }}>Update Cache</button>

    <List
      title={title}
      titleLink={titleLink}
      limit={limit}
      key={key}
      multiple={type == "pending" ? true : false}
      multipleOptions={(<>
        <button onClick={selectAll}>Select All</button>
        <button onClick={selectNone}>Select None</button>
        <button style={{marginLeft:"20px"}} onClick={multipleApprove}>Approve Selected</button>
        <button style={{marginLeft:"20px"}} onClick={multipleDelete}>Delete Selected</button>
        
      </>)}
      selected={selected}
      setSelected={setSelected}
      emptyMessage={(<div style={{display:"block",padding:"30px 10px 30px 10px",textAlign:"center",textTransform:"uppercase"}}>No {type} Events</div>)}
      updateData={updateData}
      urlGET={urlGET}
      template={(i,index)=>{
        return (
          <tr key={`item${index}`}>
            {type == "pending" ? (<>
              <td style={{width:"20px"}}>
                <input ref={(r)=>{ checkboxRef.current[index] = r; }} type="checkbox" checked={selected.includes(i)} onChange={(e)=>{
                  if(e.target.checked){
                    setSelected(s=>([...s,i]));
                  }
                  else{
                    setSelected(selected.filter(s=>(s !== i)));
                  }
                  
                }}/>
              </td>
            </>) : (<></>)}
            <th onClick={()=>setFocusItem(i)} style={{textDirection:"underline"}}>
              {i.jobTitle}
            <div style={{fontWeight:"normal"}}>{i.businessName}</div>
            {i.timeAddedString && (<div style={{fontWeight:"normal"}}>{i.timeAddedString} (Expires in {i.validThruString})</div>)}
            {i.name ? <div style={{fontWeight:"normal"}}>Posted by {i.name}</div> : (<>
              <div style={{fontWeight:"normal",color:"#fff",backgroundColor:"#666",padding:"5px",borderRadius:"5px",display:"inline-block"}}>From Feed</div>
            </>)}
            </th>
            
            <td>{i.island}</td>
            <td style={{minWidth:"50px"}}>
              <div className="options" onClick={()=>showOptions(i)}>
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Ellipsis Vertical Circle</title><circle fill="currentColor"  cx="256" cy="256" r="26"/><circle fill="currentColor" cx="256" cy="346" r="26"/><circle cx="256" cy="166" fill="currentColor"  r="26"/><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg>
              </div>
            </td>
          </tr>
        )
      }}
    />
  </>);
}
